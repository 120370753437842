import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Box, Grid } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import ImageCarousel from '../../components/image-carousel';
import CarouselVideoContainer from '../../components/video-carousel';
import Scrollbar from '../../components/scrollbar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const Album = ({ pageContext: { _album } }) => {
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1 === videoFiles.length ? 0 : prevActiveStep + 1));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? videoFiles.length - 1 : prevActiveStep - 1));
  };

  useEffect(() => {
    let _albumFiles = [];

    if (_album.albumType === 'images') {
      _album.files.forEach((_file) => {
        _albumFiles.push(_file.code);
      });

      setImageFiles(_albumFiles);
    }

    if (_album.albumType === 'videos') {
      _album.files.forEach((_file) => {
        if (_file.type === 'video/mp4') {
          _albumFiles.push(_file.code);
        }
      });
      setVideoFiles(_albumFiles);
    }

    return () => {};
  }, []);

  return (
    <Layout>
      <SectionContainerLayoutWithFilter title='GALLERY' isViewAll baseLink='/galleries'>
        <Box>
          {_album.albumType === 'images' && imageFiles ? (
            <ImageCarousel isImgTag imageFilenames={imageFiles} />
          ) : _album.albumType === 'videos' && videoFiles ? (
            <>
              <Grid container justify='space-between' alignItems='center' wrap='nowrap'>
                <Grid item>
                  <ChevronLeftIcon onClick={handleBack} fontSize='large' color='primary' />
                </Grid>
                <Grid item style={{ width: '90%' }}>
                  <CarouselVideoContainer
                    videoUrl={`${process.env.GATSBY_HLS_URL}${videoFiles[activeStep]}/stream.m3u8`}
                    showControls
                    autoPlay
                  />
                </Grid>
                <Grid item>
                  <ChevronRightIcon onClick={handleNext} fontSize='large' color='primary' />
                </Grid>
              </Grid>
              <Box mt={5}>
                <Scrollbar width='100%' height='100%'>
                  <Box display='flex'>
                    {videoFiles.map((_video, index) => (
                      <Box key={index} minWidth={100} mx={2} onClick={() => setActiveStep(index)}>
                        <CarouselVideoContainer videoUrl={`${process.env.GATSBY_HLS_URL}${_video}/stream.m3u8`} />
                      </Box>
                    ))}
                  </Box>
                </Scrollbar>
              </Box>
            </>
          ) : null}
        </Box>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default Album;
